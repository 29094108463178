<template>
  <div class="content">
    <div class="create">
      <div class="row">
        <div class="col-4">
          <router-link class="w-full vave-btn btn-blue" :to="'/faqs/create'">Add FAQ</router-link>
        </div>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col">
        <div class="loading-placeholder">
          <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4" v-else>
      <div class="col">
        <div class="table-container">
          <table class="table">
            <thead class="head">
              <tr>
                <th class="ps-4">Id</th>
                <th>Question</th>
                <th>Products</th>
                <th>Categories</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="faq in faqs.data" :key="faq.id">
                <td class="ps-4">
                  <span :title="faq.faq_id">{{faq.id}}</span>
                </td>
                <td style="max-width: 400px;">{{faq.question}}</td>
                <td style="max-width: 200px;">
                  <span class="small row" v-for="c,k in faq.products" :key="k">
                    <div class="col">{{c}}</div>
                  </span>
                </td>
                <td style="max-width: 200px;">
                  <span class="small row" v-for="c,k in faq.categories" :key="k">
                    <div class="col">{{c}}</div>
                  </span>
                </td>
                <td>
                  <div class="d-flex">
                    <router-link class="w-full vave-btn" :to="'/faqs/' + faq.id + '/edit'">
                      <i class="fa-regular fa-pen-to-square me-1"></i>
                      Edit
                    </router-link>
                    <div class="vave-btn btn-outline-red ms-2" @click="deleteFaq(faq.id)">
                      <i class="fa-regular fa-trash"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "./../http.js";
import { formatValue } from "./../formatters";

export default {
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.getFaqs();
  },
  methods: {
    formatValue,
    deleteFaq(id) {
      if (!confirm('do you want to delete the FAQ with id "' + id + '"?')) {
        return;
      }
      axios.delete("/api/admin/faqs/" + id).then(() => {
        this.$toast.success("Faq deleted");
        this.getFaqs();
      });
    },
    getFaqs(page = 1) {
      this.loading = true;
      this.faqs = {};
      axios.get("/api/admin/faqs?per_page=20&page=" + page).then(
        (r) => {
          this.loading = false;
          if (r.status == 200) {
            this.faqs = r.data;
          }
        },
        (e) => {
          this.loading = false;
          console.log(e);
        }
      );
    },
  },
  components: {},
};
</script>

<style lang="scss">
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
.small {
  font-size: 0.8rem;
}
</style>